import Button from 'components/atom/Button'

const Footer = () => {
  return (
    <footer className='pl-10 pr-10 py-16 bg-[#040513] max-[820px]:px-4'>
      <div className='flex flex-col justify-between gap-12 mx-auto max-w-80 md:max-w-full md:gap-0 md:flex-row lg:pr-44 md:mx-0'>
        <div>
          <h2 className='text-white text-4xl leading-normal font-medium max-w-[410px]'>
            Скляний електрочайник із підсвіткою Zepline
          </h2>
          <Button className='bg-white mt-10 w-full md:w-auto' />
        </div>
        <div className='flex gap-28'>
          <div>
            <h3 className='text-white font-bold text-sm'>Навігація</h3>
            <div className='flex flex-col gap-2 mt-2'>
              <p className='text-sm text-[#ABACAF]'>Про чайник</p>
              <p className='text-sm text-[#ABACAF]'>Практичні функції</p>
              <p className='text-sm text-[#ABACAF]'>Відгуки</p>
              <p className='text-sm text-[#ABACAF]'>Релевантні товари</p>
            </div>
          </div>
          <div>
            <h3 className='text-white font-bold text-sm'>Соцмережі</h3>
            <div className='flex flex-col gap-2 mt-2'>
              <p className='text-sm text-[#ABACAF]'>Instagram</p>
              <p className='text-sm text-[#ABACAF]'>Facebook</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
