import { useEffect, type FC } from 'react'
import styles from './Header.module.scss'
import { useAppDispatch, useAppSelector } from 'hooks/useTypedSelector'
import { fetchStoreArticles } from 'store/features/PrivacyPolicy/privacySlice'
import { FacebookIcon, InstagramIcon, PhoneIcon, TelegramIcon, ViberIcon } from 'assets'

const Header: FC = () => {
  const settingSlice = useAppSelector(state => state?.settingSlice)
  console.log(settingSlice)

  const dispatch = useAppDispatch()

  const privacySlice = useAppSelector((state: any) => state?.privacySlice)

  const data = privacySlice?.data

  const filteredArticles = data?.filter((article: any) => article.show_in_footer === 1)

  useEffect(() => {
    dispatch(fetchStoreArticles())
  }, [])

  function extractUsernameFromTelegramAccount(telegramAccount: string) {
    if (!telegramAccount) {
      return null
    }

    let cleanedAccount = telegramAccount.replace(/^https?:\/\/t\.me\//, '')

    cleanedAccount = cleanedAccount.replace(/^@/, '')

    return cleanedAccount
  }
  function generateViberLink(viberInfo: any) {
    if (!viberInfo) {
      return null
    }

    if (viberInfo.startsWith('http') || viberInfo.startsWith('viber')) {
      return (
        <a href={viberInfo} target='_blank'>
          <ViberIcon className='w-5' />
        </a>
      )
    }

    if (/^\+\d{2,3}/.test(viberInfo)) {
      return (
        <a href={`viber://contact?number=${encodeURIComponent(viberInfo)}`} target='_blank'>
          <ViberIcon className='w-5' />
        </a>
      )
    }

    return null
  }

  return (
    <header className={styles.wrapper}>
      <div className={styles.wrapper__container}>
        <div className={styles.wrapper__center}>
          {filteredArticles?.map((article: any, index: number) => (
            <a key={index} href={`/blog/${article.id}`} className={styles.wrapper__center__text}>
              {article.translation.title}
            </a>
          ))}
        </div>
        <div className={styles.wrapper__social}>
          <div className={styles.wrapper__social__icons}>
            {settingSlice.data?.data?.telegram_url && (
              <a
                href={`tg://resolve?domain=${extractUsernameFromTelegramAccount(settingSlice.data.data?.telegram_url)}`}
                target='__blank'
              >
                <TelegramIcon className='w-5' />
              </a>
            )}
            {generateViberLink(settingSlice.data.data?.viber_url)}

            {settingSlice.data.data?.instagram_url && (
              <a href={settingSlice.data.data.instagram_url} target='__blank'>
                <InstagramIcon className='w-5' />
              </a>
            )}
            {settingSlice.data.data?.facebook_url && (
              <a href={settingSlice.data.data.facebook_url} target='__blank'>
                <FacebookIcon className='w-5' />
              </a>
            )}
          </div>
          {settingSlice.data?.data?.phone.international && (
            <a href={`tel:${settingSlice.data.data.phone.mobile}`}>
              <div className={styles.wrapper__social__phone}>
                <PhoneIcon fill='white' />
                <p className={styles.wrapper__social__phone__number}>{settingSlice.data.data?.phone.international}</p>
              </div>
            </a>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
