import { useDispatch } from 'react-redux'
import { setIsOpen } from 'store/features/ShowOrderModal/showOrderModalSlice'

type TButton = {
  className?: string
}

const Button = ({ className }: TButton) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(setIsOpen(true))
  }

  return (
    <button className={`center rounded-[100px] px-7 py-3 font-medium ${className}`} onClick={handleClick}>
      Купити зі знижкою
    </button>
  )
}

export default Button
